import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/PageLayout.jsx";
import SEO from '../components/SEO.jsx';
import LatestPosts from '../components/Blog/LatestPosts';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO mdxType="SEO" />
    <h1>{`Oi! 👋`}</h1>
    <p>{`Meu nome é Roberto Achar, sou Full Stack Web Developer. Nasci em 1981 na cidade de São Paulo e atualmente moro em `}<a parentName="p" {...{
        "href": "https://www.google.com/maps/place/Itupeva,+State+of+S%C3%A3o+Paulo/@-23.1545841,-47.0668176,14z/data=!3m1!4b1!4m5!3m4!1s0x94cf30d56621a3c9:0xd7fe5ed81a7951c0!8m2!3d-23.1550145!4d-47.0524585"
      }}>{`Itupeva`}</a>{`, uma cidade no interior de São Paulo. Sou casado com a Adriana e temos um filho chamado Eduardo.`}</p>
    <p>{`Quero utilizar esse espaço para compartilhar o máximo de experiência e conhecimento que adquiri ao longo da minha carreira.`}</p>
    <h2>{`Blog`}</h2>
    <LatestPosts mdxType="LatestPosts" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      